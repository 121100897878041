// @import 'topo';

// @import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;400&display=swap');
@import url("https://use.typekit.net/mjy2qwg.css");

$bg: rgb(173, 2, 2);
$white: #fff;
// $white: #fff;
$black: #2c1309;
$borderWidth: 3px;
$paddingY: 0.35em;
$max-site-width: 100%;
$offwhite: #f3f3f3;
$blue: #000;
$gold: white;
$red: $blue;
$headerColor: white;


$darkBlack: #0d2641;
$nightWhite: #ebede6;
$borderWidth: 3px;

$blue: #185da9;
$gold: #fdba4a;
$red: #df5108;
$darkCheckoutPaneColor: #185500;

$sigFont: "interstate", sans-serif;
// $sigFont: courier, monospace;
$sigFontCompressed: "interstate-condensed", sans-serif;
$deco:  $sigFont;
$script:  $sigFont;

html {
  box-sizing: border-box;
}

:root {
 
  // color-scheme: light dark;
}
footer.full-site-footer {
  background: black !important;
}

#root {
  background: black;
  background: url(https://afag.imgix.net/mexcocina-cafe-authentic-mexican-food-and-cafe/bg.png?w=3000&auto=format&bri=-40&sat=-20&blur=80);
  background-size: 1000px;
  background-position: center center;
  // background-color: $bg;
  color: $black;
}
.site-content {
  // background-color: $offwhite;
}



.landing-page-wrapper {
  // background-color: $bg;
  color: white;
  a, a:visited, a:hover {
    color: white !important;
  }
}

.cart-wrapper {
  background: url(https://afag.imgix.net/mexcocina-cafe-authentic-mexican-food-and-cafe/background.jpg?w=1500&auto=format&bri=-8);
  background-size: 40%;
  background-position: center center;
  // background-color: $bg;
}
.footer {
  background-color: $bg;
}

.ordering-page {
  background-color: white;
  // color: black !important;
  // a, a:visited, a:hover {
  //   color: black !important;

  // }
}
// $sigFont: expressway, sans-serif;
.ordering-menu {
  // background: $black;
  /* background: none; */
  // color: white;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}


.promo.category {
  display: none;
}

  a.hidden-link {

    font-weight: 600;
    display: inline-block;
    padding: 0.2em 0.2em;
    margin: 0.4em 0;
    font-size: 1.5em;
    background: $blue;
    color: $gold !important;
    border-radius: 0.3em;
    text-decoration: none;
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: 1;
    animation-timing-function: fade-in; 
  }


.mexcocina.site-content {
  // background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%235cbd72' fill-opacity='0.4' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
  // background: $white;
  // @include topobackground;
  color: $white;
  a {
    // color: #ff9100;
    color: $white;
    // text-decoration-style: double;
    font-weight: 600;
    text-decoration-thickness: 2px;
    text-decoration-skip-ink: none;
  }
  a.top-nav-link {
    // color: white;
    margin-right: 1em;
    font-size: 0.85em !important;
    
  }
}


.mexcocina {
  font-family: $sigFont;

  .location-buttons {
    a.order-button {
      // display: inline-block;
      // display: none;
      margin: 0.5em;
     &.soon {
      color: lightgray !important;
      border-color: lightgray;
     } 
    }
  }
  
  .menu-nav-wrapper {
    top: 0;
  }
  .category-link-item {
    padding: 1em 0 !important;
  }
  .cart-wrapper {
    position: relative;
    
    
  }
  .cart.minimized {
    padding: 1em 1em 1em 1em;

  }
  .home-page-blocks {
    // display: flex;
    // flex-wrap: wrap;
  }
  .banner {
    font-weight: 900;
    font-size: 2em;
  }
  img.banner-text {
    color: $white;
    box-sizing: border-box;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    display: block;
    padding: 0 0.3em;
    margin-bottom: 2em;
  }
  .text-paragraph {
    margin: 2em auto;
    padding: 0 1em;
    line-height: 1.3em;
    max-width: 800px;
  }
  .online-order-card {
    background-color: black;

    padding: 1em 0 3em 0;
  }
  .text-block {
    font-weight: 200;
    font-size: 1em;
    text-align: center;
    margin: 2em 0em;
    line-height: 2em;
    // align-items: stretch;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // min-height: 6.5em;
    h2 {
      font-weight: 900;
      font-size: 2em;
      margin: 1em 0.5em;
      text-transform: uppercase;
      color: $headerColor;
      padding: 0 0.3em;
    }
    .buttons {
      // font-size: 1.3em;
    }
   
    a.order-button {
      display: inline-block;
      border: 5px solid white;
      border-radius: 3px;
      padding: 0.3em 0.5em;
      text-decoration: none;
      background: $bg;
      // color: black !important;
    }
    strong {
      font-weight: 600;
    }
    &.flavors {
      line-height: 1.5em;
      font-size: 2em;
      // text-align: left;
      h2 {
        font-weight: 900;
        color: $headerColor;
        padding: 0 0.3em;
        display: inline;
      }
      .flavor {
        // background: rgba(150,150,150,0.2);
        text-decoration: underline;
        font-weight: 900;
        display: inline-block;
        margin: 0 0.3em;
      }
    }
    &.catering {
      h2 {
        color: $headerColor;
        font-weight: 900;
        font-size: 2em;
      }
      p {
        max-width: 1000px;
        padding: 0 0.5em;
        margin: 1.5em auto;
        text-align: left;
      }
    }
    &.tagline {
      font-size: 1em;
      
      width: 100%;
      
    }
    
    &.ordering {
      width: 100%;
      a {
        line-height: 2em;
      }
    }
   
  }
  .logo-block {
    padding: 3em 0;
    img {
      margin: 0 auto;
      display: block;
      width: 100%;
      max-width: 500px;
    }
  }
  .image-block {
    img {
      margin: 0 auto;
      display: block;
      width: 100%;
      max-width: 1400px;
    }
    width: 100%;
  }
  .restaurant-name {
    font-size: 1.5em;
  }
  @media only screen and (max-width: 700px) {
    .text-block, .image-block {
      width: 100% !important;  
     }
  }

  .menu-story {

    padding-top: 0 !important;
  }
  .ordering-header {
    background-image: url(https://afag.imgix.net/los-mares/banner-1.png?w=1200&auto=format);
    text-align: center;
    background-size: cover;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    line-height: 1.5em;
    padding: 0em 0 0 0;
    .text {
      background: rgba(255,255,255,0.8);
      padding: 0.5em;
      border-radius: 0.5em;
    }
    .title {
      font-family: $deco;
      font-weight: 600;
      
    }
  }
  .product-wrapper {
    background: none !important;
    background-image: none !important;
  }
  .product-modifiers {
    display: block;
    // align-items: space-around;
 
    .modifier {
      min-height: 0em !important;
      border-radius: 0.5em;
      border: 1px solid rgba(200,200,200,0.5);
      line-height: 1.8em;
      width: 100%;
      text-align: left;
      margin: 0.1em;
      .label {
        padding: 0;
      }
      .text {
        flex-direction: row;
        justify-content: flex-start;
      }
      .price {
        flex: 1;
        text-align: right;
      }
      &.picked {
        background: #3f74cb;
        color: white;
      }
      .photo-container {
        width: auto;
        margin-right: 0.3em;
        // display: none;
        // justify-self: flex-start;
        
      }
      .modifier-name {
        // flex: 1;
      }
    }
  }
  


  .landing-page-wrapper {
    max-width: $max-site-width;
    margin: 0 auto;
    .photos.grid, .menu-category-list-view {
      max-width: 1040px;
    }

    .menu-item {
      .photo-container {
        // width: 400px;
      }
    }
  }
  .delivery-note {
    font-family: $sigFontCompressed;
    font-size: 0.6em;
  }
  a.checkout-button.button {
    background: none !important;
    color: $white;
    border-radius: 0.1em !important;
    padding: 0;
    font-weight: 800;
    box-shadow: none;
    border: 2.5px solid white !important;
    padding: 0.5em;
    color: white;
    // text-transform: capitalize;
    // display: inline-block;
    // border-bottom: 4px solid $red !important;
    // padding: 0em 0.3em !important;
    // font-size: 1em;
    // font-weight: 900 !important;
  }

  img.nav-bar-logo {
    display: block;
    position: relative;
    height: 150px;
    

    // margin-top: -0.4em;
    // bottom: -0.6em;
    padding: 0.2em 0.5em;
    @media screen and (max-width: 800px) {
      height: 50px;
    }
    @media screen and (max-width: 400px) {
      height: 30px;
    }
  }
  // .cart-wrapper {
  //   // position: relative;
  //   &:after {

  //     background:
	// 				linear-gradient(-45deg, transparent 16px, $gold 0),
	// 				linear-gradient(45deg, transparent 16px, $gold  0);
  //       background-repeat: repeat-x;
	// 	  background-position: left bottom;
  //     background-size: 22px 32px;
  //     // transition: all 0.3s;
  //     content: "";
  //     display: block;

	// 	width: 100%;
	// 	height: 13px;

 	//    position: relative;
	// 	// top:64px;
	// 	left:0px;
  //   }
  // }
    .site-top-notificaiton {
      background: black;
      color: white;
      text-align: center;
      padding: 0.5em;
      
      ;
    }
  .cart.minimized {
    font-family: $script;
    letter-spacing: 0.04em;
    font-size: 0.9em;
    // background: white;
    
    background: none;
    max-width: 1280px;
    margin: 0 auto;
    // padding-left: 0;
    // padding-bottom: 0.1em;
    border-bottom: 0px solid rgba(150,150,150,0.6);


  }
  // .hide {
  //   // position: absolute;
  //   width: 100%;
  // }
  .hero-banner {
    background-image: url(https://afag.imgix.net/beachwood-cafe/hero-food.jpg?w=1200);
    background-size: cover;
    min-height: 420px;
    // max-width: 720px;
    margin: 0 auto;
    // width: 500%;
    background-position: center 80%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-shadow: 1px 1px 1px solid black;
    p {
      font-family: $sigFontCompressed;
      font-size: 0.9em;
    }
    .icon {
      width: 300px;
      animation-name: spin;
      animation-duration: 500ms;
    }
    @keyframes spin {
      from {
        transform:rotate(0deg) scale(0);
      }
      to {transform:rotate(360deg) scale(1);}
    }

    .text {
      // height: 400px;
      position: relative;
      // bottom: -1em;

      font-family: $script;
      color: $blue;
      box-sizing: border-box;
      padding: 1em 1em;
      // border-radius: 0.2em;
      background: #d2fdf4;
      font-size: 1.5em;
      margin: 0;
      // margin-bottom: -4em;
      font-weight: 600;
      overflow: hidden;
      text-align: center;
      line-height: 0.9em;
      // box-shadow: -2px 0px 0px black;
      .small {
        font-size: 0.8em;
      }
      h1 {
        // text-transform: uppercase;
        font-family: $script;
        font-weight: 600;
        // font-family: $deco;
      }
      .info {

        font-family: $sigFontCompressed;
        font-size: 0.8em;
      }

      @media screen and (max-width: 550px) {
        font-size: 1.1em;
      }

    }
  }


  .location-info {

    padding: 1em 0.5em;
    text-align: center;
    h2 {
      font-family: $deco;
      font-size: 2em;
      margin-top: 0.5em;
    }
    p {
      margin: 0;
    }
  }




  .category-name .text {
    color: $blue;

    font-family: $deco;
    font-weight: 600;
    // text-transform: uppercase;
    font-size: 1.5em;
    // line-height: 0.7em;
    // padding: 0.3em 0.3em 0.2em 0.3em;
    border-radius: 3px;
    background: none;
    position: relative;


  }
  .menu-category-list-view {
    // text-transform: lowercase;
    .food-name {
      font-weight: 400;
      font-family: $sigFont;
      // font-size: 1.2em;
    }
    .price {
      font-family: $sigFont;
    }
    .content {
      // font-size: 1.3em;
      // width: 70%;
    }
  }

  // WINE products special display

  .top-banner {
    min-height: 50vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    // text-shadow: 1px 1px 0 $red;
    .text {
      display: inline-block;
      background: black;
      padding: 0.4em 0.5em;
      border-radius: 0.2em;
      // text-transform: uppercase;
      h1 {
        font-weight: 900;
        font-family: $deco;
      }
    }
  }

  .pages-nav {
    letter-spacing: 0.04em;
    font-size: 1em;
    max-width: $max-site-width;
    margin: 0 auto;
    font-family: $script;
    font-weight: 800;
    padding-left: 0.4em;
    display: flex;
    flex-wrap: warp;
    align-items: center;
    .react-toggle {
      margin-right: 0.5em;
    }
    ul {
      padding: 0.2em 0;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      align-items: stretch;
    }
    @media screen and (max-width: 480px) {
      font-size: 4vw;
    }
  }
  a.page-link {
    text-decoration: none;
    color: $blue;
    display: block;
    font-weight: 600;
    line-height: 0.7em;
    padding: $paddingY 0.2em calc(#{$paddingY} - #{$borderWidth}) 0.2em;
    border-bottom: $borderWidth solid #f5f5f5;
    &:hover, &.active {
      color: black;
      border-bottom: $borderWidth solid $red;

    }
  }
  a.order-online-button {
    // margin: 2em 0;
    font-size: 0.8em;
    display: inline-block;
    color: $white;
    text-decoration: none;
    padding: 0.3em 0.5em;
    margin: 0.5em 0;
    background: $red;
    font-family: $sigFontCompressed;
    font-weight: 600;
    box-shadow: none;
    // margin-right: 0.5em;
    // margin: 0.5em;
    // margin-left: 0;
    border-radius: 15px;
    box-shadow: 1px 1px #ffbc50, 3px 3px #ffbc50, 5px 5px #ffbc50, 7px 7px #ffbc50;
    transition: all 0.12s ease 0s;
    &:hover {
      transform: translate(5px, 5px);
      box-shadow: none
    }
    &.disabled:hover {
      transform: none;
      box-shadow: 1px 1px #af7b31, 3px 3px #af7b31, 5px 5px #af7b31, 7px 7px #af7b31;
    }
    &.disabled {
      background: gray;
      box-shadow: 1px 1px #af7b31, 3px 3px #af7b31, 5px 5px #af7b31, 7px 7px #af7b31;
    }
  }
  a.order-from-old {
    font-size: 0.4em;
    display: inline-block;
    color: white;
    text-decoration: none;
    padding: 0.3em 0.5em;
    background: black;
    font-family: $sigFontCompressed;
    font-weight: 600;
    box-shadow: none;
    // margin-right: 0.5em;
    // margin: 0.5em;
    // margin-left: 0;
    border-radius: 15px;
  }

  .full-menu-link-wrapper {
    padding: 1em 0;
    text-align: center;
    a.button {
      background: $red;
      color: white;
    }
  }
    .menu-item {
      header {
        // font-family: $sigFont;
        // font-size: 1.2em;
      }
    }

    .footer {
      display: none;
      background-color: darkred;
    }
    
    // .footer {
    //   // .content {
    //   //   display: none;
    //   // }
    //   font-size: 0.8em;
    //   //padding-top: 1em;
    //   // margin-top: 0 !important;
    //   font-family: $sigFontCompressed;
    //   // text-align: right;
    //   // padding-right: 220px;
    //   color: $blue;
    //   // background: url(https://afag.imgix.net/los-mares/footer.png?w=1200&auto=format) no-repeat;
    //   background: $bg;
    //   background-position: center;
    //   background-size: 350px;
    //   min-height: 500px;
    //   padding-bottom: 0;
    //   // background: none;
    //   a.phone-link {
    //     color: inherit;
    //   }
    // }

  }

  .page-title {

    font-family: $deco;
    font-weight: 600;
    // font-size: 2em;
    padding: 1em 0;
    text-align: center;
  }

  .photos {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 800px;
    margin: 0 auto;
    .photo-wrapper {

      width: 45%;
      img {
        border: 4px solid black;
        width: 100%;
      }
    }
  }


  .menu-category-list-view {
    .mag-glass-svg {
      display: none;

    }
  }
  .category-name .text::before {
    content: " ";
    display: inline-block;
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,w_90/y5yh0erwriedwgz6aabt) no-repeat;
    background-size: contain;
    // width: 28px;
    // height: 32px;
    // margin-right: -0.1em;
    width: 55px;
    height: 75px;
    // margin-right: -0.1em;
    position: absolute;
    left: -0.55em;
    top: -0.55em;
  }
  .page-header {
    max-width: 650px;
    margin: 0 auto;
    p {
      line-height: 1.3em;
    }
  }
  .page-title {
    text-align: center;
    font-size: 1.5em;
    font-weight: 900;
    font-family: $deco;
    text-transform: uppercase;
    margin-top: 1em;
  }
  .contact-page {
    .top-banner {
      margin: 1em 0;
      width: 100%;
    }
    max-width: 650px;
    margin: 0 auto;
    margin-bottom: 2em;
    padding: 0 0.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.5em;
    strong {
      font-weight: 600;
    }
    h2 {
      font-weight: 900;
      font-family: $deco;
      text-transform: uppercase;
      margin: 1em 0;
    }
    ul {
      list-style: disc;
      padding-left: 1em;
    }
    li, p {
      margin: 0.5em 0;
    }
  }
  


// Dark Mode for ordering
.mexcocina.site-content {
  .back-link-wrapper {
    background-color: #000;
    box-shadow: none;;
    a {
      color: white !important;
      border-bottom: 3px solid white;

    }
  }
  .product-wrapper {
    color: white;

  }
  .info-group {
    
      color: white;
    
  }
  .product-modgroup {
    background-color: black;
  }
  .category {
    margin-top: 2.5em;
  }
  .menu-category-list-view {
    
    max-width: 1200px !important;
  }
  .photos.grid {
    max-width: 1200px !important;
  }
  .menu-nav-wrapper {
    background-color: black;
    border-bottom-color: black !important;
    a.category-link-item {

      color: white !important;
    }
  }
  .menu-story {
    color: white;
    background: url(https://afag.imgix.net/mexcocina-cafe-authentic-mexican-food-and-cafe/bg.png?w=3000&auto=format&bri=-40&sat=-20&blur=50);
    background-size: 50%;
    background-position: center center;

  }
  .category-name {
    
    .text {
      color: white;
    }
  }
  .menu-item {
    background-color: black !important;
    border: 2px solid rgba(150,150,150,0.3);
    a.item-link {
      color: white;
    }
    box-sizing: border-box;
    &:hover {
      border: 2px solid rgba(150,150,150,0.5);
      box-shadow: 0px 0px 5px 0px rgba(200,200,200,0.8);

      // border: 3px solid rgba(200,200,200,0.5);
    } 
  }

  .menu-story .category-name .text {
    color: $nightWhite;
  }
  .product-wrapper {
    color: $nightWhite;
    background-color: #222;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23bdbdbd' fill-opacity='0.18' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
    .product-info {
      color: $nightWhite;
    }
    .panel, .product-modgroup, .incomplete-note {
      background: #000;
      box-shadow: 0px 2px 4px #0f0f0f;
    }
  }

  .checkout-container {
    color: black;
    .back {

      a {
        color: black;
      }
    }
    .step-number {
      background: $nightWhite;
      color: $darkBlack;
    }
  }
  .actions {
    .button {
      color: $darkBlack;
      font-weight: 500;
    }
  }
  .note-input {
    background-color: #333;
    color: white;
  }

  // .cart-type-container {
  //   background: $darkCheckoutPaneColor;
  //   color: $nightWhite;
  // }

  // .checkout__cash-payment {
  //   color: $nightWhite;
  //   background: $darkCheckoutPaneColor;
  // }
  // .price-summary {
  //   color: $nightWhite;
  // }
  // .switch-button-group, .cart-type-container {
  //   color: $nightWhite;
  //   label.selected {
  //     background: $darkCheckoutPaneColor;
  //   }
  //   label.not-selected {
  //     background: #8bb487;
  //   }
  // }
  // .place-order-section {
  //   background: $darkCheckoutPaneColor;
  //   .place-order-button {
  //     background: #16b7ff;
  //   }
  // }
  // .footer {
  //   background: #111;
  // }

}

.path__checkout.site-content {
  
  background: rgba(250,250,250);
}

.mpath_order-status.site-content {
  background: rgba(250,250,250);
  a {
    color: black;
  }
}





.table-page {
  .table-controls {
    padding: 1em 0;
    text-align: center;
    font-size: 2em;
    .number {
      font-size: 2em;
    }
  }
  a.order-button {
    // display: inline-block;
    // display: none;
    margin: 0.5em;
    display: inline-block;
      border: 5px solid white;
      border-radius: 3px;
      padding: 0.3em 0.5em;
      text-decoration: none;
      background: $bg;
  }
}